<template>
  <div class="content" style="margin-top: -50px">
    <div class="text-center">
      <vue-whatsapp-widget
        phoneNumber="+524421177251"
        companyName="cabo.party (Marissa)"
        textReply="Typically replies within 10-15 mins (9am - 10pm)"
        companyLogo="https://imgix.cosmicjs.com/4674bea0-3e73-11ec-9828-0f2f00f103e0-telegram-peer-photo-size-1-5166023334515814245-1-0-0.jpg?h=50"
      />

      <h1
        class="tulum-party-title"
        style="
          margin-bottom: 0;
          font-size: 2.6rem;
          letter-spacing: 2px;
          margin-top: 45px;
        "
      >
        LOS CABOS CLUBS
      </h1>
      <br/>

      <card
        class="card text-center col-sm-12 col-md-10 offset-md-1"
        style="z-index: 1"
      >
        <template slot="header">
          <h2
            class="tulum-party-subtitle"
            style="font-size: 0.9rem; z-index: 10; background-color: #100f0f"
          >
            Top Clubs
          </h2>
          <p class="text-center">
            <router-link :to="{path: '/article/article-the-nightlife-guide-of-los-cabos'}">Cabo’s Nightlife
            </router-link>
            is defined by its exclusive clubs. For years, <strong>San Jose Cabo</strong> and <strong>Cabo San Lucas</strong>
            have been at the center of the party scene in the Californias.

            We list the <strong>Top Los Cabos </strong> Clubs you do not want to
            miss this
            <strong class="label label-info font-weight-700">{{
                getCurrentMonth
              }}</strong>
          </p>
          <p style="font-size: 0.9rem; margin-top: 20px">
            <strong>Top Clubs</strong> to eat, dance & have fun.
          </p>
          <br/>
          <card class="card text-center" style="border: 2px solid black">


            <img v-lazy="clubsSpirt" width="214px" height="59px">

            <br>
            <br>
            <div class="form-group">
              <select @change="goToClub($event)" class="select">
                <option>Select Club</option>
                <option
                  class=""
                  v-for="(row, idx) in sortedVenues"
                  :key="idx"
                  v-bind:value="row.slug"
                >
                  {{ row.title }}
                </option>
              </select>
            </div>
            <br>

            <RsvpViaDialog></RsvpViaDialog>

            <br>
            <br>
            <strong style="margin-top: 10px">
              Los Cabos elite clubs party.
            </strong>
            <br>
            <small >
              We operate from 9AM - 10PM / Answer within 15 mins</small
            >
            <br/>
            <small>NO EXTRA FEE</small>
          </card>
        </template>
      </card>
    </div>

    <div class="row">
      <card
        class="card text-center col-sm-12 col-md-6"
        v-for="(row, idx) in venues"
        :key="idx"
        style="box-shadow: #000000 10px 10px 2px; padding: 40px"
      >
        <template slot="header">
          <h2 class="tulum-party-title" style="margin-top: -1px;font-weight: bolder;font-size: 1.8rem;text-transform: uppercase">
            {{ row.title }}
          </h2>
          <router-link :to="{ name: 'Club', params: { slug: row.slug } }">
            <img
              class="card-img"
              v-lazy="row.metadata.main_image.imgix_url + '?h=230&q=&fit=clamp&w=386&format=auto'"
              :alt="row.title"
              height="230px"
              v-if="row.metadata.main_image"
              style="box-shadow: 0px 10px 15px 0px black"
            />
            <img
              v-if="row.metadata.logo && idx > 0"
              v-bind:class="row.metadata.code.toLowerCase()"
              v-lazy="row.metadata.logo.imgix_url + '?h=45&w=85&auto=format'"
              height="45px"
              width="85px"
              style="margin-top: 20px; max-width: 100px"
              :alt="row.title"
            />
            <hr class="brand-title-black" style="height: 1px"/>
          </router-link>
          <router-link
            :to="{ name: 'Club', params: { slug: row.slug } }"
            class="badge-pill"
            style="
              background-color: #23d5ab;
              margin-bottom: 5px;
              color: black !important;
            "
            v-if="incomingClubParties[row.title]"
          >{{ incomingClubParties[row.title].length }} Upcoming Events
          </router-link>
          <br v-if="incomingClubParties[row.title]"/>
          <hr class="brand-title-black" style="height: 4px"/>
          <div
            v-html="row.metadata.description"
            class="md-small-hide"
            style="color: #fff !important; margin-bottom: 10px;"
          ></div>
          <br/>
          <ClubDetails :config="ClubTagsConfig[row.slug]"></ClubDetails>
        </template>

        <br>
        <br>
        <template slot="default">
          <router-link
            :to="{ name: 'Club', params: { slug: row.slug } }"
            class="btn font-weight-bold btn-primary"
          >
            + CLUB INFO & EVENTS
          </router-link>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import ClubDetails from '@/components/Clubs/ClubDetailsTable';
// import ClubFilter from "@/components/ClubFilter";
import clubsTableConfig from '../../common/clubsTableConfig';

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );
const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
    );

export default {
  name: 'Clubs.vue',
  data: () => {
    return {
      showAll: false,
      showPalms: false,
      showModal: true,
      ClubTagsConfig: {tags: []},
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      clubsSpirt:
        'https://imgix.cosmicjs.com/7a8a8c30-0d36-11ef-9eca-7d347081a9fb-clubs-index.jpg',
      activityImg:
        'https://imgix.cosmicjs.com/3f6794f0-6cfe-11ee-a874-d142b6892265-activity.png',
      djImage:
        'https://imgix.cosmicjs.com/ec030ce0-f3e4-11ee-b555-0d0678c27dd7-Orthodox-priest-with-white-beard-listening-to-musi.jpg',
      mainImage:
        'https://imgix.cosmicjs.com/031c4bf0-e6e8-11ec-9447-f98173199613-telegram-cloud-photo-size-1-4972161472985672258-y.jpg?duotone=black,ff6491&duotone-alpha=100',
      questionClub: '',
      showReserve: false,
      questionType: 'info',
    };
  },
  metaInfo: () => ({
    title:
      "Los Cabos - Top Clubs | Reservations · Events · Tickets",
    link: [{rel: 'canonical', href: 'https://www.cabo.party/los-cabos-clubs'}],
    meta: [
      {
        name: 'description',
        content:
          'Clubs to Dance & Have Fun. Information & Reservations for the best clubs in Los Cabos, Mexico.' +
          'Bagatelle Los Cabos, Taboo Los Cabos',
      },
      {
        name: 'keywords',
        content:
          'Los Cabos, Cabos San Lucas, nightlife, Techno, clubs, beach clubs, Tonight, Taboo, Bagatelle, Crania',
      },
      {
        property: 'og:title',
        content: `Los Cabos | Top Clubs to Dance & Have Fun. Tickets & Smart RSVP.`,
      },
      {property: 'og:type', content: 'website'},
      {property: 'og:locality', content: 'Los Cabos'},
      {property: 'og:country-name', content: 'Mexico'},
      {
        property: 'og:description',
        content: `Top Clubs Information & Reservations. Cabo San Lucas Top Clubs.`,
      },
      {
        property: 'og:image',
        content:
          'https://imgix.cosmicjs.com/bc3f0f60-f62b-11ee-b555-0d0678c27dd7-Taboo_Los_Cabos___tabooloscabos.jpg',
      },
    ],
  }),
  components: {
    ClubDetails,
    // ClubFilter,
    RsvpViaDialog,
    VueWhatsappWidget,
  },
  computed: {
    incomingParties: {
      get() {
        return this.$store.state.incomingParties;
      },
    },
    incomingClubParties: {
      get() {
        return this.$store.state.incomingClubParties;
      },
    },
    venues: {
      get() {
        return this.$store.state.venues;
      },
    },
    tags: {
      get() {
        return [
          ...new Set(
            this.$store.state.venues
              .map((v) => v.metadata.tags.split('|'))
              .flat()
          ),
        ];
      },
    },
    categories: {
      get() {
        let h = {};
        this.$store.state.venues.forEach((venue) => {
          venue.metadata.tags.split('|').forEach((tag) => {
            if (h[tag]) {
              h[tag].push(venue.title);
            } else {
              h[tag] = [venue.title];
            }
          });
        });
        return h;
      },
      // return this.$store.state.venues.map(x => x.metadata.tags.split("|")).flat().filter((v,i,a) => a.indexOf(v) === i);
    },
    getCurrentMonth: {
      get() {
        let d = new Date();
        return (
          [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ][d.getMonth()] +
          ' ' +
          d.getFullYear()
        );
      },
    },
    sortedVenues: {
      get() {
        return [...this.$store.state.venues].sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      },
    },
  },
  created() {
    // alert( Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.$store.dispatch('getVenues', {start: 0, limit: 14});
    this.$store.dispatch('getParties');
    this.ClubTagsConfig = clubsTableConfig;
    window.history.scrollRestoration = 'manual';
  },
  updated() {
    // this.ClubTagsConfig = clubsTagsConfig;
    setTimeout(() => (this.showPalms = true), 5000);
  },
  methods: {
    paginate() {
      this.showAll = true;
      this.$store.dispatch('getVenues', {start: 12, limit: 20});
    },
    goToClub(event) {
      let path = '';
       path = '/los-cabos-clubs/' + event.target.value;
      this.$router.push({
        path: path,
        replace: true,
      });

      this.$rollbar.info('QUICK CLUB: ' + path);
    },
  },
};
</script>


